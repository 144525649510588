<template>
  <div />
</template>

<script>
import { user_certificationDecide } from '@/api/user'
export default {
  data() {
    return {
      productModuleId: ''
    }
  },
  created() {
    this.productModuleId = this.$route.query.productModuleId
    this.check()
    if (window.name === 'proDetail' || window.name === '') {
      console.log('created 首次被加载')
      window.name = 'proDetail' // 在首次进入页面时我们可以给window.name设置一个固定值
      sessionStorage.removeItem('jump')
      sessionStorage.setItem('shareUrl', '/order/shiplist_fastCart?productModuleId=' + this.productModuleId)
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
  },
  methods: {
    check() {
      this.$toast.loading({ message: '数据加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      user_certificationDecide().then(res => {
        this.$toast.clear()
        if (res.data.certificationDecide !== 1) {
          this.$router.replace('/home/PreCertification')
        } else { this.$router.replace('/order/shiplist_fastCart?productModuleId=' + this.productModuleId) }
      })
    }
  }
}
</script>

